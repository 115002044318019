/*
 * Author: hackerwand
 * Email: heipi@hackerwand.com
 * Date: Thu Jan 20 2022
 */
import store from '@/store'
import { loadImgs } from '@/components/base/file/libv2'
import { fileRequest, workerRequest, hisWorkRequest, workerEduRequest, workerCertificateRequest, rewPunRequest, trainRequest } from '@/api'

export async function getWorkerAvatar(id, size) {
  return new Promise(async resolve => {
    try {
      const response = await fileRequest.download({ id, size })
      resolve(window.URL.createObjectURL(response))
    } catch (error) {
      resolve(null)
    }
  })
}

let workDataCache = {}
export async function getWorkData(id, refresh = false) {
  if (refresh || !workDataCache[id]) {
    workDataCache[id] = hisWorkRequest.get({ workerId: id })
  }

  return await workDataCache[id]
}

let workerEduCache = {}
export async function getEduData(id, refresh = false) {
  if (refresh || !workerEduCache[id]) {
    workerEduCache[id] = workerEduRequest.get({ workerId: id })
  }

  return await workerEduCache[id]
}

let workerListCache = null
export async function getWorkerList(refresh = false) {
  if (refresh || !workerListCache) {
    workerListCache = new Promise(async resolve => {
      let res = await workerRequest.get()
      let list1 = []
      let List2 = []
      res.forEach(v => {
        if (v.position !== null && v.position !== '' && !isNaN(v.position)) {
          list1.push(v)
        } else {
          List2.push(v)
        }
      })
      list1.sort((a, b) => Number(a.position) - Number(b.position))
      list1.sort((a, b) => Number(a.position) - Number(b.position))
      res = list1.concat(List2)
      resolve(res)
    })
  }

  return await workerListCache
}

const workerInfoCache = {}
export async function getWorkerInfo(id, refresh = false) {
  if (refresh || !workerInfoCache[id]) {
    workerInfoCache[id] = new Promise(async resolve => {
      try {
        const data = await workerRequest.get({ id: id })
        if (data.length > 0) {
          resolve(data[0])
        } else {
          resolve(null)
        }
      } catch (error) {
        resolve(null)
      }
    })
  }
  return await workerInfoCache[id]
}

export const certs = [
  {
    label: '学历证书', name: 'edu', async get(id) {
      const data = await getEduData(id)
      return loadImgs(data, (item) => item.type || '学历证书')
    },
    async getData (id) {
      const data = await getEduData(id)
      const promise = data.map(async v => {
        v.sourceName = v.type || '学历证书'
        v.sources = await loadImgs([v], v.sourceName, true)
        return v
      })
      return Promise.all(promise)
    }
  },
  {
    label: '专业技术职务聘任证书', name: 'hire', async get(id) {
      const data = await workerCertificateRequest.get({ type: 'pro_hire', workerId: id })
      return loadImgs(data, (item) => item.hireWorker || '证书')
    },
    async getData (id) {
      const data = await workerCertificateRequest.get({ type: 'pro_hire', workerId: id })
      const promise = data.map(async v => {
        v.sourceName = v.hireWorker || '证书'
        v.sources = await loadImgs([v], v.sourceName, true)
        return v
      })
      return Promise.all(promise)
    }
  },
  {
    label: '职业资格证书', name: 'doc_qua', async get(id) {
      const data = await workerCertificateRequest.get({ type: 'doctor_qua', workerId: id })
      const types = store.getters.selectDatas['qualification_certificate_type'];
      return loadImgs(data, (item) => {
        const type = types.find(v => v.key === item.xlmc)
        const xlmcText = type ? type.label : null
        return xlmcText || '证书'
      })
    },
    async getData (id) {
      const data = await workerCertificateRequest.get({ type: 'doctor_qua', workerId: id })
      const types = store.getters.selectDatas['qualification_certificate_type'];
      const getName = (item) => {
        const type = types.find(v => v.key === item.xlmc)
        const xlmcText = type ? type.label : null
        return xlmcText || '证书'
      }
      const promise = data.map(async v => {
        v.sourceName = getName(v)
        v.sources = await loadImgs([v], v.sourceName, true)
        return v
      })
      return Promise.all(promise)
    }
  },
  {
    label: '专业技术资格证书', name: 'qua', async get(id) {
      const data = await workerCertificateRequest.get({ type: 'pro_qua', workerId: id })
      return loadImgs(data, (item) => item.certificateTitle || '证书')
    },
    async getData (id) {
      const data = await workerCertificateRequest.get({ type: 'pro_qua', workerId: id })
      const promise = data.map(async v => {
        v.sourceName = v.certificateTitle || '证书'
        v.sources = await loadImgs([v], v.sourceName, true)
        return v
      })
      return Promise.all(promise)
    }
  },
  {
    label: '执业证书', name: 'pra', async get(id) {
      const data = await workerCertificateRequest.get({ type: 'doctor_pra', workerId: id })
      return loadImgs(data, (item) => item.mType || '证书')
    },
    async getData (id) {
      const data = await workerCertificateRequest.get({ type: 'doctor_pra', workerId: id })
      const promise = data.map(async v => {
        v.sourceName = v.mType || '证书'
        v.sources = await loadImgs([v], v.sourceName, true)
        return v
      })
      return Promise.all(promise)
    }
  },
  {
    label: '其他证书', name: 'other', async get(id) {
      const data = await workerCertificateRequest.get({ type: 'other', workerId: id })
      return loadImgs(data, (item) => item.mType || '证书')
    },
    async getData (id) {
      const data = await workerCertificateRequest.get({ type: 'other', workerId: id })
      const promise = data.map(async v => {
        v.sourceName = v.mType || '证书'
        v.sources = await loadImgs([v], v.sourceName, true)
        return v
      })
      return Promise.all(promise)
    }
  },
  {
    label: '身份证', name: 'idcard', async get(id) {
      const data = await getWorkerInfo(id)
      const items = []
      if (data.idCardFrontFileId) {
        items.push({
          name: '正面',
          fileIds: data.idCardFrontFileId
        })
      }
      if (data.idCardBackFileId) {
        items.push({
          name: '背面',
          fileIds: data.idCardBackFileId
        })
      }
      return loadImgs(items, (item) => item.name || '身份证')
    },
    async getData (id) {
      const data = await getWorkerInfo(id)
      const items = []
      if (data.idCardFrontFileId) {
        items.push({
          id: data.idCardFrontFileId,
          name: '正面',
          fileIds: data.idCardFrontFileId
        })
      }
      if (data.idCardBackFileId) {
        items.push({
          id: data.idCardBackFileId,
          name: '背面',
          fileIds: data.idCardBackFileId
        })
      }
      const promise = items.map(async v => {
        v.sourceName = v.name
        v.sources = await loadImgs([v], v.sourceName, true)
        return v
      })
      return Promise.all(promise)
    }
  },
  {
    label: '奖励信息', name: 'rew', async get(id) {
      const data = await rewPunRequest.get({ workerId: id, type: 'rew' })
      return loadImgs(data, (item) => item.title || '证书')
    },
    async getData (id) {
      const data = await rewPunRequest.get({ workerId: id, type: 'rew' })
      const promise = data.map(async v => {
        v.sourceName = v.title || '证书'
        v.sources = await loadImgs([v], v.sourceName, true)
        return v
      })
      return Promise.all(promise)
    }
  },
  {
    label: '培训信息', name: 'train', async get(id) {
      const data = await trainRequest.get({ workerId: id, type: 'rew' })
      return loadImgs(data, (item) => item.pxxm || '证书')
    },
    async getData (id) {
      const data = await trainRequest.get({ workerId: id, type: 'rew' })
      const promise = data.map(async v => {
        v.sourceName = v.pxxm || '证书'
        v.sources = await loadImgs([v], v.sourceName, true)
        return v
      })
      return Promise.all(promise)
    }
  }
]